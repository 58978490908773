<template>
    <div class="safe-content">
        <div class="cont-header">
           <span :selected="checkIdx==1" @click="onClickIdx(1)">安全绑定</span>
           <span :selected="checkIdx==2" @click="onClickIdx(2)">修改密码</span>
        </div>
        <div class="user-cont" v-if="checkIdx==1">
            <user-phone :data="info" @success="onSuccess"></user-phone>
            <user-email :data="info" @success="onSuccess"></user-email>
        </div>
        <div class="cont" v-if="checkIdx==2">
            <div>
                 <a-form :form="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" :colon="false" labelAlign="right" :hideRequiredMark="true">
                    <a-form-item label="旧密码">
                        <a-input-password
                            placeholder="请输入旧密码"
                            v-decorator="['name', { rules: [{ required: true, message: '请输入旧密码!' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="新密码">
                        <a-input-password
                            placeholder="请输入新密码"
                            v-decorator="['password', { rules: [{ required: true, message: '请输入新密码!' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="确认新密码">
                        <a-input-password
                            placeholder="请再次确认输入新密码"
                            v-decorator="['repassword', { rules: [{ required: true, message: '请再次确认输入新密码!' },{validator: compareToFirstPassword}] }]"
                        />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
                        <a-button type="primary" style="width: 156px;height:40px;" @click="handleSubmitPassword()">
                            确认提交
                        </a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
    </div>
</template>
<script>
import {Spin} from 'ant-design-vue';
import UserPhone from './components/UserPhone.vue';
import UserEmail from './components/UserEmail.vue';
export default {
    data() {
      return {
          checkIdx: 1,
          form: this.$form.createForm(this, "user-paddowrd"),
          info:{},
      }
    },
    components: {
      UserPhone, 
      UserEmail,
      ASpin: Spin,
    },
    created() {
      this.getData();
    },
    methods: {
        onClickIdx(idx){
            this.checkIdx = idx;
        },
        handleSubmitPassword(){
            this.form.validateFields((err, values) => {
                if (!err) {
                    this.ModifyPass(values);
                    // console.log("待开发");
                }
            });
        },
        ModifyPass(param) {
            this.$axios.newPwd(param).then(res => {
                const data = res.data;
                if (data && data.code == 0) {
                    this.$message.success(this.$t('template.sthIsSuccess', {name: this.$t('common.change1')}));
                    // this.$store.dispatch('outLogin', {
                    //     router: this.$router
                    // });
                }
            })
        },
        onSuccess() {
            this.getData();
        },
        getData() {
            this.$axios.Account_info().then(res => {
                const data = res.data;
                if (data && data.code == 0) {
                    this.info = data.data;
                }
            })
        },
        compareToFirstPassword() {
            this.$root.$validate.confirm_pass_to_first(this.form, ...arguments);
        },
    },
}
</script>
<style lang="scss" scoped>
    .ant-input{
        border-radius: 2px;
        height: 40px;
    }
    .cont-header{
        height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            line-height: 66px;
            text-align:center;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
            margin-left: 10px;
        }
        span:nth-child(1){
            margin-left: 39px;
        }
        span:nth-child(2){
            margin-left: 45px;
        }
        span[selected]{
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
        span:hover{
            cursor: pointer;
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
    }
    .user-cont{
        width: 80%;
        margin: 56px auto;
    }
    .cont{
        width: 49.6%;
        margin: 0 auto;
        padding-top:41px;
        text-align:center;
        p:nth-child(1){
            height: 24px;
            font-size: 18px;
            font-weight: 500;
            color: #333333;
            line-height: 24px;
        }
        p:nth-child(2){
            height: 24px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
    }
</style>